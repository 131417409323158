// Descriptive font-size
$font-family-karla: 'Karla', sans-serif;

// Defaults
$font-family-sans-serif: $font-family-karla;
$font-family-serif: serif !default;
$font-family-monospace: 'PT Mono', 'Menlo', 'Consolas', 'Courier', monospace !default;

// Functional font-families
$font-family-display: $font-family-sans-serif !default;
$font-family-text: $font-family-sans-serif !default;
$font-family-helper: $font-family-sans-serif !default;
