// # Colors
// ## Descriptive colors
// Only use descriptive colors to define functional colors in this document.
$color-black: #333333;
$color-white: #ffffff;
$color-grey: #cccccc;
$color-yellow: #E3BE00;
// $color-purple: #8C5383;
$color-blue: #2672B9;
$color-red: #DC1272;
$color-green: #00471e;
$color-light-green: #F7FAEE;
$color-accent-green: #7dff9a;

// ## Functional colors
// Always use a descriptive color to define a functional color. Always use the right functional color even when two (or more) functional colors have the same descriptive color (e.g. $color-heading and $color-primary). This will help when you want to target a specific color.

// ### Hierarchical
$color-call-to-action: $color-blue !default;
$color-primary: $color-blue !default;
// $color-secondary: $color-purple !default;
 
// ### Typography
$color-heading: $color-black !default;
$color-subheading: $color-grey !default;
$color-text: $color-black !default;
$color-text-diap: $color-white !default;

// ### User Interface
$color-ui: $color-grey !default;
$color-ui-accent: $color-blue !default;

// ### States
$color-error: $color-red !default;
$color-success: $color-green !default;
$color-warning: $color-yellow !default;
// $color-info: $color-blue !default;
// $color-select: $color-purple !default;
// $color-focus: $color-green !default;

// ## Additional colors
// Can be used for shared color variables in different components (e.g. $color-icon), in specific state (e.g. $color-logged-in) or themes (e.g. $color-corporate)
$color-shadow: $color-black !default;
$color-background: $color-white !default;
$color-background-accent: #f9f9f9 !default;

// ## Ranges
// Please keep the same variable order as above

// $color-call-to-action-90: #F9F2CC !default;
$color-call-to-action-100: $color-call-to-action !default;
// $color-call-to-action-200: #DCB100 !default;

// $color-primary-90: #F9F2CC !default;
$color-primary-100: $color-primary !default;
$color-primary-200: shade($color-primary, 20%) !default;

$color-ui-80: tint($color-ui, 75%) !default;
$color-ui-90: tint($color-ui, 50%) !default;
$color-ui-100: $color-ui !default;

// ## Social media colors
// $color-facebook: #3b5998 !default;
// $color-twitter: #00a0d1 !default;
// $color-linkedin: #0e76a8 !default;
// $color-youtube: #c4302b !default;
// $color-pinterest: #c8232c !default;
// $color-instagram: #3f729b !default;
// $color-google-plus: #db4a39 !default;
