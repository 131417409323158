.modal-content {
	@include media-breakpoint-down(sm) {
		width: calc(100% + 30px);
		height: auto;
		border-width: 10px;
	}
	@include media-breakpoint-down(xs) {
		max-width: 100%;
		border-width: 10px;
	}
	border-color: transparentize($color: #e6ede9, $amount: 0.2);

	.modal-body {
		@include media-breakpoint-down(xs) {
			padding: 0.5rem;
		}
		padding: 1rem;

		.intro {
			margin-bottom: 1rem;
			padding: 1rem;
		}
	}
}

.modal-content--up {
	@include media-breakpoint-down(md) {
		margin-top: -100px;
	}
	@include media-breakpoint-down(sm) {
		margin-top: -50px;
		margin-bottom: 2rem;
	}
	@include media-breakpoint-down(xs) {
		margin-top: -30px;
	}
	margin-top: -180px;
	margin-bottom: 4rem;
}

.modal-dialog {
	@include media-breakpoint-down(sm) {
		margin: 0 0.5rem;
		min-height: 100%;
		max-width: none;
		max-height: 100%;
	}
}

.modal-header {
	@include media-breakpoint-down(xs) {
		padding: 0.5rem;
	}
	border-bottom-width: 1px !important;
}

.modal-footer {
	padding: 1rem 0;
	border: none;
}
