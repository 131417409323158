$range-palm: (320px, 440px) !default;
$range-palm-s: (320px, 360px) !default;
$range-palm-m: (361px, 400px) !default;
$range-palm-l: (401px, 440px) !default;

$range-hand: (441px, 680px) !default;
$range-hand-s: (441px, 520px) !default;
$range-hand-m: (521px, 600px) !default;
$range-hand-l: (601px, 680px) !default;

$range-lap: (681px, 1040px) !default;
$range-lap-s: (681px, 800px) !default;
$range-lap-m: (801px, 920px) !default;
$range-lap-l: (921px, 1040px) !default;

$range-desk: (1041px, 2180px) !default;
$range-desk-s: (1041px, 1280px) !default;
$range-desk-m: (1281px, 1640px) !default;
$range-desk-l: (1641px, 2180px) !default;

$breakpoints-map: (
	palm: 0,
	hand: 441px,
	lap: 681px,
	desk: 1041px
) !default;

$breakpoints-map-nonzero: (
	palm: 320px,
	hand: 441px,
	lap: 681px,
	desk: 1041px
) !default;
