@mixin jumbotron-bg($name: 'bg-1') {
	@include media-breakpoint-down(md) {
		background: url('../images/#{$name}@0,5x.jpg') no-repeat center center;
		background-size: cover;

		@media screen and (min-resolution: 120dpi) {
			background: url('../images/#{$name}.jpg') no-repeat center center;
			background-size: cover;
		}
	}
	@include media-breakpoint-up(md) {
		background: url('../images/#{$name}.jpg') no-repeat center center;
		background-size: cover;

		@media screen and (min-resolution: 120dpi) {
			background: url('../images/#{$name}@2x.jpg') no-repeat center center;
			background-size: cover;
		}
	}
}

.jumbotron--green {
	@include padding($spacing*2);
	min-width: 100%;
	background-color: var(--green-10);
}

.jumbotron-hero {
	@include media-breakpoint-down(md) {
		padding-bottom: 60vw;
	}
	@include media-breakpoint-up(md) {
		padding-bottom: 27vw;
	}
	background: $green-10 url('../images/home-hero.jpg') no-repeat bottom center;
	background-size: cover;

	@media screen and (min-resolution: 120dpi) {
		background: $green-10 url('../images/home-hero@2x.jpg') no-repeat bottom center;
		background-size: cover;
	}
}

.jumbotron-background-image-1 {
	@include jumbotron-bg('bg-1');
}

.jumbotron-background-image-2 {
	@include jumbotron-bg('bg-2');
}
