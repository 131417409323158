$opacity-10: 0.1 !default;
$opacity-20: 0.2 !default;
$opacity-30: 0.3 !default;
$opacity-40: 0.4 !default;
$opacity-50: 0.5 !default;
$opacity-60: 0.6 !default;
$opacity-70: 0.7 !default;
$opacity-80: 0.8 !default;
$opacity-90: 0.9 !default;
$opacity-100: 1 !default;
