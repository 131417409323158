.custom-checkbox {
	position: relative;
	display: block;
	margin-bottom: 12px;
	padding-left: 35px;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
	position: absolute;
	width: 0;
	height: 0;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	width: 22px;
	height: 22px;
	border: 1px solid $gray-600;
	border-radius: 4px;
	background-color: white;
}

.custom-checkbox input:checked ~ .checkmark {
	border-color: $pink;
	background-color: $pink;
}

.checkmark:after {
	position: absolute;
	display: none;
	content: '';
}

.custom-checkbox input:checked ~ .checkmark:after {
	display: block;
}

.custom-checkbox .checkmark:after {
	top: 2px;
	left: 6px;
	width: 8px;
	height: 12px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
}
