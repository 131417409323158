.page-footer {
	position: relative;
	background-color: $green;
	color: $white;
	line-height: 2;

	// &::before {
	// 	display: block;
	// 	width: 100%;
	// 	height: 100px;
	// 	background: {
	// 		image: url('/dist/images/floral-pattern-40@2x.jpg');
	// 		repeat: repeat;
	// 		size: 512px 512px;
	// 	};
	// 	content: '';
	// }

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		position: relative;
		z-index: 100;
		display: inline-block;
		color: $white;

		&::after {
			position: absolute;
			top: 0.60em;
			right: -0.25em;
			left: -0.25em;
			z-index: -10;
			width: calc(100% + 0.5em);
			height: 0.75em;
			background-color: rgba($bright-green, 0.3);
			content: '';
		}
	}

	a {
		@include hover() {
			color: $green-30;
			text-decoration: $link-hover-decoration;
		}
		background-color: transparent; // Remove the gray background on active links in IE 10.
		color: $green-50;
		text-decoration: $link-decoration;
	}

	small {
		display: inline-block;
		color: $green-50;
		line-height: 1.2;

		a {
			text-decoration: $link-hover-decoration;
		}
	}
}
