$src: '' !default;

@import 'vendors/index';

@import 'settings/index';

@import 'vendor-overrides/index';

@import 'components/index';

@import 'layouts/index';

@import 'controls/index';

// Developer added 
.container--light {
	background-color: $color-light-green;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeOutHalf {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0.5;
	}
}

@-moz-keyframes fadeOutHalf {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0.5;
	}
}

@keyframes fadeOutHalf {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0.5;
	}
}

#conversation {
	ul {
		&.conversation {
			.conversation__left {
				-webkit-animation: fadeIn 0.5s ease-in;
				   -moz-animation: fadeIn 0.5s ease-in;
				        animation: fadeIn 0.5s ease-in;

				&.not-active {
					-webkit-animation: fadeOutHalf 0.5s ease-in;
					   -moz-animation: fadeOutHalf 0.5s ease-in;
					        animation: fadeOutHalf 0.5s ease-in;
					-webkit-animation-fill-mode: forwards;
					   -moz-animation-fill-mode: forwards;
					        animation-fill-mode: forwards;
				}
			}
		}
	}
}

.conversation_options {
	// transition: height .5s;
	a {
		// -webkit-animation: fadeIn 0.5s ease-in;
		//    -moz-animation: fadeIn 0.5s ease-in;
		//         animation: fadeIn 0.5s ease-in;
	}
}

.conversation__right {
	&.is-active {
		background-color: #c6145b;
	}

	&.not-active {
		-webkit-animation: fadeOutHalf 0.5s ease-in;
		   -moz-animation: fadeOutHalf 0.5s ease-in;
		        animation: fadeOutHalf 0.5s ease-in;
		-webkit-animation-fill-mode: forwards;
		   -moz-animation-fill-mode: forwards;
		        animation-fill-mode: forwards;
	}
}

.conversation_options {
	min-height: 107px;
}
