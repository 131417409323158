.header-with-span {
	span {
		position: relative;
		z-index: 100;
		white-space: nowrap;

		// display: inline-block;

		&::after {
			position: absolute;
			top: 0.60em;
			right: -0.25em;
			left: -0.25em;
			z-index: -10;
			width: calc(100% + 0.5em);
			height: 0.75em;
			background-color: $bright-green;
			content: '';
		}
	}
}

.header--centered {
	@include padding($spacing null);
	text-align: center;
}

.header--border-top {
	padding: 1rem 0;
	border-top: 1px solid var(--green-10);
}
