.frame {
	display: flex;
	overflow: hidden;
	min-height: 280px;
	width: 100%;
	flex-flow: column nowrap;
	justify-content: flex-end;
	align-items: baseline;

	.modal-dialog & {
		min-height: 80vh;
	}
}

.conversation-container {
	display: flex;
	flex-direction: column-reverse;
	overflow-y: scroll;
	padding-right: 40px;
	min-height: 40px;
	max-height: 100%;
	width: calc(100% + 40px);
	background: url('/dist/images/chat-bg.png');
	flex-grow: 1;
}

.conversation {
	@include media-breakpoint-down(md) {
		padding: 1.5rem 1rem 3rem ;
	}
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	margin: 0;
	padding: 1.5rem 2rem;
	list-style: none;
	flex: 0 0 auto;
}

// .conversation {
// 	position: absolute;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	display: flex;
// 	flex-direction: column;
// 	margin: 0;
// 	padding: 0;
// 	padding: 1.5rem 2rem;
// 	width: auto;
// 	list-style: none;
// }

.conversation__left {
	margin-bottom: 4px;
	padding: 1.5rem;
	max-width: 75%;
	border-radius: 1.5rem;
	background-color: $green-10;
	align-self: flex-start;

	&.first {
		border-radius: 1.5rem 1.5rem 1.5rem 4px;
	}

	&.between {
		border-radius: 4px 1.5rem 1.5rem 4px;
	}

	&.last {
		border-radius: 4px 1.5rem 1.5rem 1.5rem;
	}
}

.conversation__right {
	overflow: hidden;
	margin-bottom: 4px;
	padding: 1.5rem;
	max-width: 75%;
	border-radius: 1.5rem 1.5rem 4px 1.5rem;
	background-color: $primary;
	color: $white;
	cursor: pointer;
	align-self: flex-end;

	img {
		display: block;
		margin: -30px -30px 20px;
	}

	&.first {
	}

	&.between {
	}

	&.last {
	}
}

.conversation__options {
	margin-top: 12px;
}

.conversation__img-btn {
	position: relative;
	overflow: hidden;
	padding-top: 124px;
	min-width: 150px;
	border-radius: 1rem;
	text-align: left;

	img {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 120px;
		// border-radius: 0.75rem 0.75rem 0 0;
	}
}

.conversation__color {
	display: inline-block;
	margin-right: 0.5rem;
	width: 18px;
	height: 18px;
	border: 2px solid white;
	vertical-align: middle;

	&[style*='background-color: white'] {
		border: 2px solid $green-10;
	}
}

.conversation_options {
	position: absolute;
	padding: 30px;
	width: 100%;
	border-top: 5px solid $green-10;
	background-color: white;
}

.conversation {
	margin-bottom: 150px;
}
