$icon-size-80: 12px !default;
$icon-size-90: 14px !default;
$icon-size-100: 16px !default;
$icon-size-200: 18px !default;
$icon-size-300: 20px !default;
$icon-size-400: 22px !default;
$icon-size-500: 24px !default;
$icon-size-600: 32px !default;
$icon-size-700: 48px !default;
$icon-size-800: 64px !default;
$icon-size-900: 72px !default;
$icon-size-1000: 96px !default;
