.payment-methods {
	display: flex;
}

.payment-methods__item {
	margin-right: $spacing;

	&:last-child {
		margin-right: 0;
	}
}
