a {
	text-decoration: none;

	&.link-back {
		text-decoration: underline;

		&::before {
			display: inline-block;
			float: left;
			margin-top: 3px;
			margin-right: 0.4rem;
			margin-left: 1rem;
			width: 18px;
			height: 18px;
			background: url('../images/arrow-left-light.svg') no-repeat left center;
			background-size: contain;
			content: '';
		}
	}

	&.link--light {
		margin-right: 1rem;
		color: $green-50;
	}
}

.arrow-tinted-text-dark {
	margin: 0;
	padding-left: 0;
	list-style: none;

	li {
		display: flex;
		margin: 0.75rem 0;
		align-items: flex-start;
		justify-content: flex-start;

		i {
			margin: 0.25rem 0.5rem 0 0;
			color: $pink;
		}

		a {
			color: $gray-800;
		}
	}
}
