$line-height-20: 1 !default;
$line-height-30: 1.0625 !default;
$line-height-40: 1.125 !default;
$line-height-50: 1.1875 !default;
$line-height-60: 1.25 !default;
$line-height-70: 1.3125 !default;
$line-height-80: 1.375 !default;
$line-height-90: 1.4375 !default;
$line-height-100: 1.5 !default;
$line-height-200: 1.5625 !default;
$line-height-300: 1.625 !default;
$line-height-400: 1.6875 !default;
$line-height-500: 1.75 !default;
$line-height-600: 1.8125 !default;
$line-height-700: 1.875 !default;
$line-height-800: 1.9375 !default;
$line-height-900: 2 !default;
