.navbar-brand {
	@include media-breakpoint-down(xs) {
		max-width: 50%;

		img {
			width: 100%;
			height: auto;
		}
	}
}

.navbar-home {
	background-color: $green-10;
}
