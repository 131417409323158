.dlist {
	margin-bottom: 1rem;

	&__title {
		margin-bottom: 0.5rem;
		font-weight: bold;
	}

	&__item {
		margin-bottom: 0.4rem;

		span {
			padding-left: 0.5rem;
			font-style: italic;
		}
	}
}
