@mixin arrow($top: 0, $left: 20%) {
	position: relative;
	z-index: 1000;

	&::before {
		@include media-breakpoint-down(xs) {
			top: 100%;
			left: 50%;
		}
		position: absolute;
		top: #{$top};
		left: #{$left};
		display: block;
		width: 19px;
		height: 19px;
		background-color: inherit;
		content: '';
		transform: translate(-50%, -50%) rotate(45deg);
	}
}

.balloon-top-left {
	@include arrow(0, 20%);
}

.balloon-top {
	@include arrow(0, 50%);
}

.balloon-top-right {
	@include arrow(0, 80%);
}

.balloon-right-top {
	@include arrow(20%, 100%);
}

.balloon-right {
	@include arrow(50%, 100%);
}

.balloon-right-bottom {
	@include arrow(80%, 100%);
}

.balloon-bottom-left {
	@include arrow(100%, 20%);
}

.balloon-bottom {
	@include arrow(100%, 50%);
}

.balloon-bottom-right {
	@include arrow(100%, 80%);
}

.balloon-left-top {
	@include arrow(20%, 0);
}

.balloon-left {
	@include arrow(50%, 0);
}

.balloon-left-bottom {
	@include arrow(80%, 0);
}
