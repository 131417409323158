// Vendors
//
// Vendor-dependencies are loaded in this section. The import statements are
// treated like local imports, while the source-files for these dependencies are
// located in the node_modules folder. The mapping to these folders is done in
// the gulpfile.js in the var 'scssIncludePaths'. The path should be pointing to
// the folder containing the css or scss-files.
//
// Style guide: Vendors

// @import 'normalize';
@import 'bourbon';
@import 'flickity';
