.carousel-cell {
	@include media-breakpoint-only(lg) {
		width: 80%;
	}
	@include media-breakpoint-up(xl) {
		width: 66%;
	}
	@include media-breakpoint-down(md) {
		flex-direction: column;
		height: auto;
		align-items: flex-start;
		justify-content: center;
	}
	position: relative;
	display: flex;
	margin-right: 10px;
	min-height: 560px;
	width: 100%;
	height: auto;
	counter-increment: carousel-cell;
	justify-content: space-between;
	align-items: center;

	@media screen and (min-width: 1600px) {
		width: 50%;
	}

	@media screen and (min-width: 2200px) {
		width: 30%;
	}
}

.carousel-cell__image {
	@include media-breakpoint-down(md) {
		margin: 0 auto;
		max-width: 80%;
		height: auto;
	}
	position: relative;
	z-index: 1;
}

.carousel-cell__jumbotron {
	@include padding($spacing*3.5);
	position: relative;
	z-index: 2;
	margin: 0;
	max-width: 30%;
	border-top-left-radius: 0.1rem;

	&.jumbotron--green {
		@include media-breakpoint-up(xl) {
			min-width: 500px;
			transform: translateX(-75%);
		}
		@include media-breakpoint-up(lg) {
			min-width: 460px;
			transform: translateX(-80%);
		}
		@include media-breakpoint-down(md) {
			margin: -30px auto 0;
			min-width: 60%;
			max-width: 60%;
		}
	}
}

.flickity-button {
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.flickity-enabled:focus .flickity-viewport {
	outline: none;
}

.usp {
	color: $green;
	font-size: $font-size-base * 2.5;
	font-weight: $font-weight-bold;
	font-style: italic;
	line-height: 1.2;
}
