.input-group-prepend {
	border-right-width: 0;

	& + .form-control {
		border-left-width: 0;
	}
}

.input-group {
	.form-control {
		border-right-width: 0;
	}

	.input-group-prepend {
		border-right-width: 0;

		& + .form-control {
			border-right-width: 1px;
			border-left-width: 0;
		}
	}

	.input-group-append {
		border-left-width: 0;
	}
}

.form--inset {
	@include media-breakpoint-down(xs) {
		margin: 1rem 1rem 0;
	}
	margin: 3rem 3rem 0;
}
