.card {
	border-width: 0;
	box-shadow: 0 5px 15px rgba($black, 0.1);
}

.card-footer {
	border-width: 0;
}

.card-unstyled {
	border-width: 0;
	box-shadow: none;

	.card-body {
		@include padding(null 0 0);
	}

	.card-footer {
		@include padding(0 0 null 0);
	}
}

.section-background-image-1 {
	@include jumbotron-bg('bg-1');
	@include media-breakpoint-down(md) {
		padding-top: 10rem;
	}
	@include media-breakpoint-up(md) {
		padding-top: 14rem;
	}
	padding-bottom: 5rem;
}

.section--pull-over {
	@include media-breakpoint-down(md) {
		margin-top: -6rem;
	}
	@include media-breakpoint-up(md) {
		margin-top: -8rem;
	}

	.card-body {
		padding: 1.5rem 1.5rem 1rem 1.5rem;
	}

	.card-footer {
		padding: 0 1.5rem 1.5rem 1.5rem;
	}
}

.radius-lg {
	@include border-radius($border-radius-lg);

	.card-footer:last-child {
		@include border-radius(0 0 $border-radius-lg $border-radius-lg);
	}
}

.card--break-out-bottom {
	box-shadow: 0 5px 15px rgba($black, 0.2);

	.card-body {
		padding: 0.75rem;
	}

	.pull-up {
		@include media-breakpoint-up(sm) {
			margin-top: -1.75rem;
		}
	}

	.text-tinted {
		color: $green;
	}

	.subscript {
		@include media-breakpoint-up(md) {
			overflow: hidden;
			max-width: 66%;
			max-height: 53px;
		}
		@include media-breakpoint-only(sm) {
			margin-top: 1.5rem;
		}
		@include media-breakpoint-down(sm) {
			max-width: 80%;
		}
		line-height: 1.1;
	}

	.tag--pull-down-sm {
		@include media-breakpoint-up(sm) {
			position: relative;
			bottom: -2rem;
			height: 80px;
		}
		@include media-breakpoint-down(sm) {
			position: absolute;
			top: 0;
			right: 0;
			height: 70px;
		}
	}
}
